const baselayers = {
  'Topo':
  {
    'url': 'https://tile.osm.ch/switzerland/{z}/{x}/{y}.png',
    'maxZoom': 22,
    'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    'default': 1
  },
  'Topo (einfach)':
  {
    'url': 'https://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png',
    'maxZoom': 22,
    'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    'default': 0
  },
  'Satellit':
  {
    'url': 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    'maxZoom': 20,
    'attribution': 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    'default': 0
  },
  'Lokal':
  {
    'url': 'osm-local/{z}/{x}/{y}.png',
    'maxZoom': 17,
    'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    'default': 0
  },
}
module.exports = baselayers;
