window.mode = "antarctica";

import {loadMap} from './map.js';

import markerClusterGroup from 'leaflet.markercluster';
import layerSupport from 'leaflet.markercluster.layersupport';
import geocoder from 'leaflet-control-geocoder';
import locate from 'leaflet.locatecontrol';
import Fullscreen from 'leaflet-fullscreen';

import 'proj4leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-extra-markers';
import 'leaflet.control.layers.tree';
import 'leaflet-sidebar-v2';
import 'leaflet-geotiff-2';
import "leaflet-geotiff-2/dist/leaflet-geotiff-rgb";
import 'leaflet.heat/dist/leaflet-heat.js';
import 'leaflet.gridlayer.googlemutant'

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.css';
import './css/style.css';

const queryString = window.location.search;
window.urlParams = new URLSearchParams(queryString);

window.proj = {};

window.proj['EPSG4326'] = L.CRS.EPSG4326;
window.proj['EPSG3031'] = new L.Proj.CRS(
   "EPSG:3031",
   "+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs ", {
   origin: [-4194304, 4194304],
   resolutions: [
      8192.0,
      4096.0,
      2048.0,
      1024.0,
      512.0,
      256.0
   ],
   bounds: L.bounds(
      [-4194304, -4194304],
      [4194304, 4194304]
   )
});

window.proj['EPSG9354'] = new L.Proj.CRS(
   "EPSG:9354",
   "+proj=stere +lat_0=-90 +lat_ts=-65 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs", {
   origin: [-4388309.03, 4433435.01],
   resolutions: [
      8192.0,
      4096.0,
      2048.0,
      1024.0,
      512.0,
      256.0
   ],
   //bounds: L.bounds(
   //   [-4388309.03, -4433435.01],
   //   [4388309.03, 4433435.01]
   //)
});

//const southWest = L.latLng(-36.652178, -134.706917);
//const northEast = L.latLng(-36.652178, 45.293083);
//const southWest = L.latLng(-40.0, -90.0);
//const northEast = L.latLng(-40.0, 90.0);
const southWest = L.latLng(-35.0, -135.0);
const northEast = L.latLng(-35.0, 45.0);
const bounds = L.latLngBounds(southWest, northEast);

var map = L.map('map', {
  preferCanvas: false,
  fullscreenControl: {
    pseudoFullscreen: false, // if true, fullscreen to page width and height
    position: 'topright'
  },
  //crs: window.proj['EPSG3031'],
  crs: window.proj['EPSG9354'],
  //maxBounds: bounds,
  attributionControl: true,
  zoomControl: false,
  scrollWheelZoom: true,
  tap: false
}).setView([-90, 0], 0);

var moreLayers = []

if (!L.Browser.mobile) {
   new L.Control.Zoom({ position: 'topright' }).addTo(map);
}

window.addEventListener("load", loadMap(map, mode, moreLayers));
