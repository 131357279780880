import Autolinker from 'autolinker';

export function loadMap(map, mode, moreLayers) {

  const config = require('./config-' + mode);
  const baselayers = require('./basetree-' + mode);
  const sidebartop = require('./sidebar-top-' + mode);
  const sidebarbottom = require('./sidebar-bottom-' + mode);
  const geojson = require('./geojson-' + mode);
  const tiles = require('./tiles-' + mode);
  const geotiffs = require('./geotiffs-' + mode);

  function getSetting(s, def) {
    s = config[s];
    if (!s) { return def; }
    return s;
  }

  const urlSelected = urlParams.has('selected') ? urlParams.get('selected').split(",") : [];

  var basetree = {
    label: 'Basiskarten',
    collapsed: true,
    children: []
  };

  Object.entries(baselayers).forEach(([key, options]) => {
    let layer = addLayer(options);
    basetree['children'].push({ label: key, layer: layer });
    if (options['default']) {
      layer.addTo(map);
    }
  });

  function addLayer(options) {
    if (options['type'] && options['type'] == 'wms') {
      var layer = L.tileLayer.wms(options['url'], {
        layers: options['layers']
      });
      if (options['transparent']) layer.wmsParams.transparent = options['transparent'];
      if (options['format']) layer.wmsParams.format = options['format'];
    } else if  (options['type'] && options['type'] == 'google') {
      var layer = L.gridLayer
	.googleMutant({
           type: "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
	})
    } else {
      var layer = L.tileLayer(options['url']);
      if (options['transparent']) layer.options.transparent = options['transparent'];
      if (options['format']) layer.options.format = options['format'];
    }
    if (options['attribution']) layer.options.attribution = options['attribution'];
    if (options['crs']) layer.options.crs = window.proj[options['crs']];
    if (options['layer']) layer.options.layer = options['layer'];
    if (options['minZoom']) layer.options.maxZoom = options['minZoom'];
    if (options['maxZoom']) layer.options.maxZoom = options['maxZoom'];
    if (options['tileMatrixSet']) layer.options.tileMatrixSet = options['tileMatrixSet'];
    if (options['tileSize']) layer.options.tileSize = options['tileSize'];
    if (options['zIndex']) layer.options.zIndex = options['zIndex'];
    return layer;
  }

  var completePoints = false;
  var overlaytree = {};
  var autolinker = new Autolinker( { newWindow: true, truncate: 25 } );

  function createMarkerIcon(icon, prefix, markerColor, iconColor) {
    return L.ExtraMarkers.icon({
      icon: icon,
      svg: true,
      prefix: prefix,
      markerColor: markerColor,
      iconColor: iconColor
    });
  }

  function centerAndZoomMap(points) {
    var lat = map.getCenter().lat, latSet = false;
    var lon = map.getCenter().lng, lonSet = false;
    var zoom = 12, zoomSet = false;
    var center;

    if (getSetting('_initLat') !== '') {
      lat = getSetting('_initLat');
      latSet = true;
    }

    if (getSetting('_initLon', '') !== '') {
      lon = getSetting('_initLon');
      lonSet = true;
    }

    if (getSetting('_initZoom', '') !== '') {
      zoom = parseInt(getSetting('_initZoom'));
      zoomSet = true;
    }

    if (urlParams.has('lat') && urlParams.has('lon') && urlParams.has('zoom')) {
      lat = urlParams.get('lat')
      lon = urlParams.get('lon')
      center = L.latLng(lat, lon);
      zoom = urlParams.get('zoom')
    } else {
      if ((latSet && lonSet) || !points) {
        center = L.latLng(lat, lon);
      } else {
        center = points.getBounds().getCenter();
      }
      if (!zoomSet && points) {
        zoom = map.getBoundsZoom(points.getBounds());
      }
    }
    map.setView(center, zoom);
  }

  function determineLayers(points, geojson, tiles, geotiffs) {
    var groups = [];
    var categories = [];
    var elements = {};
    var layers = {};
    var links = {};
    overlaytree['label'] = "Ebenen";
    overlaytree['children'] = [];

    //var categories2 = points.map(p => p.Kategorie);
    //categories2 = [...new Set(categories2)];
    points.forEach ((point) => {
      var category = point.Kategorie;
      var group = point.Gruppe;
      if (category && categories.indexOf(category) === -1) {
        categories.push(category);
      }
      if (typeof elements[category] === 'undefined') {
        elements[category] = [];
      }
      if (elements[category].indexOf(group) === -1) {
        elements[category].push(group)
      }
      //if (elements[category].indexOf(group + " Heatmap") === -1 && getSetting("_heatmap").includes(group)) {
      //  elements[category].push(group + " Heatmap")
      //}
    });
    moreLayers.forEach ((layer) => {
      var category = layer.category;
      var group = layer.name;
      if (category && categories.indexOf(category) === -1) {
        categories.push(category);
      }
      if (typeof elements[category] === 'undefined') {
        elements[category] = [];
      }
      if (elements[category].indexOf(group) === -1) {
        elements[category].push(group)
      }
    });
    geojson.forEach ((file) => {
      var category = file.category;
      var group = file.name;
      if (file.link) {
        links[group] = '<a href="' + file.link + '" id="menu_link_' + file.name + '">' + file.name + '</a>';
      }
      if (category && categories.indexOf(category) === -1) {
        categories.push(category);
      }
      if (typeof elements[category] === 'undefined') {
        elements[category] = [];
      }
      if (elements[category].indexOf(group) === -1) {
        elements[category].push(group)
      }
    });
    tiles.forEach ((tile) => {
      var category = tile.category;
      var group = tile.name;
      if (category && categories.indexOf(category) === -1) {
        categories.push(category);
      }
      if (typeof elements[category] === 'undefined') {
        elements[category] = [];
      }
      if (elements[category].indexOf(group) === -1) {
        elements[category].push(group)
      }
    });
    geotiffs.forEach ((geotiff) => {
      var category = geotiff.category;
      var group = geotiff.name;
      if (category && categories.indexOf(category) === -1) {
        categories.push(category);
      }
      if (typeof elements[category] === 'undefined') {
        elements[category] = [];
      }
      if (elements[category].indexOf(group) === -1) {
        elements[category].push(group)
      }
    });
    categories.forEach ((category) => {
      var element = []
      element['label'] = category;
      element['selectAllCheckbox'] = true
      element['children'] = [];
      element['eventedClasses'] = {}
      let menuOpen = getSetting('_menuOpen');
      if (menuOpen.includes(category) || urlSelected.includes(category)) {
        element['collapsed'] = false;
      } else {
        element['collapsed'] = true;
      }
      elements[category].forEach ((group) => {
        layers[category + '_' + group] = L.layerGroup();
        var subelement = {};
        if (links[group]) {
          subelement['label'] = links[group];
        } else {
          subelement['label'] = group;
        }
        subelement['layer'] = layers[category + '_' + group];
        element['children'].push(subelement);
      });
      if (getSetting("_heatmap").includes(category)) {
        layers[category + '_heatmap'] = L.layerGroup();
        var subelement = []
        subelement['label'] = "Heatmap";
        subelement['layer'] = layers[category + '_heatmap'];
        element['children'].push(subelement);
      }
      overlaytree['children'].push(element);
    });

    return layers;
  }

  function mapTiles(tiles, layers) {
    tiles.forEach((options) => {
      let layer = addLayer(options);
      if (layers !== undefined && layers.length !== 1) {
        layer.addTo(layers[options.category + '_' + options.name]);
      }
    });
  }

  function mapMore(moreLayers, layers) {
    moreLayers.forEach((options) => {
      let layer = options.data;
      if (layers !== undefined && layers.length !== 1) {
        layer.addTo(layers[options.category + '_' + options.name]);
      }
    });
  }

  function mapgeoTiffs(geotiffs, layers) {
    //const renderer = L.LeafletGeotiff.rgb();
    geotiffs.forEach((options) => {
      //let layer = L.leafletGeotiff(options['url'], {
      //   'renderer': renderer
      //});
      let layer = L.imageOverlay(options['url'], options['bounds']);
      //if (options['crs']) layer.options.crs = window.proj[options['crs']];
      if (options['zIndex']) layer.options.zIndex = options['zIndex'];
      if (layers !== undefined && layers.length !== 1) {
        layer.addTo(layers[options.category + '_' + options.name]);
      }
    });
  }

  async function mapGeojson(geojson, layers) {
    geojson.forEach (async (file) => {
      var mapStyle = {};
      if (file['type'] && file['type'] == 'text') {
        mapStyle = {
          "color": file['color']
        };
      } else {
        mapStyle = {
          "color": file['color'],
          "fillcolor": file['color'],
          "weight": file['width'] || 1,
          "fillOpacity": file['opacity'] || 0.65
        };
      }
      if (file['icon']) {
        var marker = createMarkerIcon(file['icon'], 'fa', file['color'].toLowerCase(), 'white');
      }
      await fetchDataJson(file['url']).then((json) => {
        if (marker || (file['type'] && file['type'] == 'text')) {
          var layer = new L.geoJSON(json, {
            pointToLayer: function (feature, latlng) {
              if (file['type'] && file['type'] == 'text') {
                return L.tooltip(latlng, {content: feature.properties['Place_Name'].replace('#','<br/>'), permanent: true});
              } else {
                return L.marker(latlng, {icon: marker});
              }
            },
            style: mapStyle
          });
        } else {
          var layer = new L.geoJSON(json, {
            style: mapStyle
          });
        };
        if (layers !== undefined && layers.length !== 1) {
          layer.addTo(layers[file.category + '_' + file.name]);
        }
      });
    });
  }

  function mapPoints(points, layers) {
    var markerArray = [];
    let popupDisabled = getSetting('_popupDisabled');

    getSetting('_heatmap').forEach((heat) => {
      var heatmapPoints = [];
      var category = '';
      var group = '';
      //var heatpoints = points.filter(p => p.Gruppe == heat);
      var heatpoints = points.filter(p => p.Kategorie == heat);
      heatpoints.forEach((point) => {
        heatmapPoints.push([point.Latitude, point.Longitude, point.HeatValue]);
        category = point.Kategorie;
        group = point.Gruppe;
      });
      
      var heatmap = L.heatLayer(heatmapPoints, {
        gradient: {0.4: 'blue', 0.55: 'lime', 1: 'red'},
        radius: 40,
        blur: 25,
        maxZoom: 5,
        minOpacity: 0.4,
      });
      //heatmap.addTo(layers[category + '_' + group + ' Heatmap']);
      heatmap.addTo(layers[category + '_heatmap']);
    });

    // check that map has loaded before adding points to it?
    points.forEach((point) => {

      var size = point['Icon Size'] ? point['Icon Size'].split(',').map(Number) : [32, 32];
      var anchor = [size[0] / 2, size[1]];

      var icon = (point['Marker Icon'].indexOf('.') > 0)
        ? L.icon({
          iconUrl: point['Marker Icon'],
          iconSize: size,
          iconAnchor: anchor
        })
        : createMarkerIcon(point['Marker Icon'],
          'fa',
          point['Marker Color'].toLowerCase(),
          point['Icon Color']
        );

      if (point.Latitude !== '' && point.Longitude !== '') {
        if (popupDisabled.includes(point.Gruppe)) {
          var marker = L.marker([point.Latitude, point.Longitude], {
            icon: icon,
          });
        } else {
          let routingInclude = '';
          if (getSetting('_mapRouting')) {
            routingInclude = '<p><a href="javascript:startRouting(' + [point.Latitude, point.Longitude] + ');">Route</a></p>';
          }  
          let video = point['Video'] ? point['Video'] : '';
          var marker = L.marker([point.Latitude, point.Longitude], {
            icon: icon,
            name: point['Name'],
            image: point['Image'],
            description: point['Beschreibung'],
            video: video
            })
            .bindPopup(
            (point['Name'] ? ('<h3>' + point['Name'] + '</h3><h4>' + point['Gruppe'] + '</h4>') : ('<h3>' + point['Gruppe'] + '</h3>')) + 
            '<table>' +
            (point['Datum'] ? ('<tr><td><strong>Datum</strong></td><td>' + point['Datum'] + '</td><tr>') : '') +
            (point['Adresse'] ? ('<tr><td style="width: 60px;"><strong>Adresse</strong></td><td>' + point['Adresse'] + '</td><tr>') : '') +
            (point['Kontakt'] ? ('<tr><td><strong>Kontakt</strong></td><td>' + autolinker.link(point['Kontakt']) + '</td><tr>') : '') +
            (point['Öffnungszeit'] ? ('<tr><td><strong>Öffnungszeit</strong></td><td>' + point['Öffnungszeit'] + '</td><tr>') : '') +
            (point['Kinder'] ? ('<tr><td><strong>Kinder</strong></td><td>' + point['Kinder'] + '</td><tr>') : '') +
            (point['Boviseinheiten'] ? ('<tr><td><strong>Boviseinheiten</strong></td><td>' + point['Boviseinheiten'] + '</td><tr>') : '') +
            (point['Online'] ? ('<tr><td><strong>Online</strong></td><td>' + autolinker.link(point['Online']) + '</td><tr>') : '') +
            (point['Quelle'] ? ('<tr><td><strong>Quelle</strong></td><td>' + autolinker.link(point['Quelle']) + '</td><tr>') : '') +
            '</table>' + 
            '<p><a href="javascript:openSidebar(\'info\');">Mehr Information...</a></p>' +
            routingInclude, {
            minWidth: 260
          });
        };

        if (!popupDisabled.includes(point.Gruppe)) {
          marker.on('click', markerOnClick);
        }

        if (layers !== undefined && layers.length !== 1) {
          marker.addTo(layers[point.Kategorie + '_' + point.Gruppe]);
        }

        markerArray.push(marker);
      }
    });

    function markerOnClick(e) {
      var infopane = '';
      if (e.target.options.video && e.target.options.video != "") {
        infopane += e.target.options.video;
      } else if (e.target.options.image && e.target.options.image != "") {
        infopane += '<img src="' + e.target.options.image + '" alt="' + e.target.options.name + '"/>';
      }
      const toDelete = /<p><a href="javascript:openSidebar.*Route<\/a><\/p>$/;
      infopane += e.target._popup._content.replace(toDelete, '');
      //infopane += e.target._popup._content.replace('<p><a href="javascript:openSidebar(\'info\');">Mehr Information...</a></p>', '');
      if (e.target.options.description && e.target.options.description != "") {
        infopane += '<p>' + e.target.options.description + '</p>';
      }
      document.getElementById("infocontent").innerHTML = infopane;
    }

    var group = L.featureGroup(markerArray);
    var clusters = (getSetting('_markercluster', 'off') === 'on') ? true : false;

    // if layers.length === 0, add points to map instead of layer
    if (layers === undefined || layers.length === 0) {
      map.addLayer(
        clusters
        ? L.markerClusterGroup({ chunkedLoading: true }).addLayer(group).addTo(map)
        : group
      );
    } else {
      if (clusters) {
        // Add multilayer cluster support
        var multilayerClusterSupport = L.markerClusterGroup.layerSupport();
        multilayerClusterSupport.addTo(map);

        Object.entries(layers).forEach ((layer) => {
          multilayerClusterSupport.checkIn(layer[1]);
        });
      }
      Object.entries(layers).forEach ((layer) => {
        var items = layer[0].split('_');
        var category = items[0];
        var element = items[1];
        let categorySelected = getSetting('_categorySelected');
        let groupSelected = getSetting('_groupSelected');
        if (categorySelected.includes(category) && element != "heatmap") {
          layer[1].addTo(map);
        }
        if (urlSelected.includes(category)) {
          layer[1].addTo(map);
        }
        if (groupSelected.includes(element)) {
          layer[1].addTo(map);
        }
        if (urlSelected.includes(element)) {
          layer[1].addTo(map);
        }
        //urlSelected.forEach ((item) => {
        //  console.log(item);
        //});
      });

      var pos = (getSetting('_pointsLegendPos', 'off') == 'off')
        ? 'topleft'
        : getSetting('_pointsLegendPos', 'topleft');

      var pointsLegend = L.control.layers.tree([basetree], overlaytree, {
        namedToggle: false,
        closedSymbol: '&#8862;',
        openedSymbol: '&#8863;',
        position: pos,
        collapsed: false,
      });

      pointsLegend.addTo(map);

    }

    completePoints = true;
    return group;
  }

  function onMapDataLoad(points) {

    document.title = getSetting('_mapTitle');

    // Add point markers to the map
    var layers;
    var group = '';
    if (points && points.length > 0) {
      layers = determineLayers(points, geojson, tiles, geotiffs);
      group = mapPoints(points, layers);
      mapMore(moreLayers, layers);
      mapGeojson(geojson, layers);
      mapTiles(tiles, layers);
      mapgeoTiffs(geotiffs, layers);
    } else {
      completePoints = true;
    }

    centerAndZoomMap(group);

    // Show info in console
    map.on("click", function(ev) {
      console.log("Latitude: " + ev.latlng["lat"]);
      console.log("Longitude: " + ev.latlng["lng"]);
      //var ln = ev.latlng["lng"],
      //  lt = ev.latlng["lat"];
      //var api = "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat="+lt+"&lon="+ln;                       
      //fetchDataJson(api).then((data) => {
      //  console.log(data);
      //});
    });

    // create the sidebar instance and add it to the map
    var sidebar = L.control.sidebar({
      autopan: true,
      container: 'sidebar'
    }).addTo(map);
    
    Object.entries(sidebartop).forEach(([key, value]) => {
      let panecontent = '<p>' + value['description'] + '<p>'
      if (key == "Home" && Object.keys(sidebartop).length > 1) {
        let homepane = "<h3>Informationen</h3>";
        homepane += '<ul>'
        Object.entries(sidebartop).forEach(([key, value]) => {
          homepane += '<li><a href="javascript:openSidebar(\'' + key.toLowerCase() + '\');">' + key + '</a></li>';
        });
        homepane += '</ul>'
        panecontent += homepane
      }
      if (key == "Notfall") {
        let notfallpane = "<h3>Wichtige Nummern</h3>";
        Object.entries(window.notfall).forEach(([key, value]) => {
          notfallpane += '<h4>' + key + '</h4>';
          notfall[key].forEach ((item) => {
            notfallpane += '<h5>' + item['name'] + '</h5>';
            notfallpane += '<p><a href="' + item['phone'] + '">' + item['phone'] + '</a><br/><a href="' + item['url'] + '">' + item['webpage'] + '</a><br/></p>';
          });
        });
        panecontent += notfallpane
      }

      if (value['sites']) {
        panecontent += '<h3 id="' + key.toLowerCase() + '-webpages" >Wichtige Seiten</h3>'
        panecontent += '<ul>';
        value['sites'].forEach ((item) => {
          panecontent += '<li><a href="' +  item['url'] + '" target=_blank">' + item['name'] + '</a></li>';
        });
        panecontent += '</ul>';
      }
      if (value['documents']) {
        panecontent += '<h3 id="' + key.toLowerCase() + '-documents" >Wichtige Dokumente</h3>'
        panecontent += '<ul>';
        value['documents'].forEach ((item) => {
          panecontent += '<li><a href="' +  item['url'] + '">' + item['name'] + '</a></li>';
        });
        panecontent += '</ul>';
      }
      if (value['books']) {
        panecontent += '<h3 id="' + key.toLowerCase() + '-books" >Wichtige Bücher</h3>'
        panecontent += '<ul>';
        value['books'].forEach ((item) => {
          panecontent += '<li><a href="' +  item['url'] + '">' + item['name'] + '</a></li>';
        });
        panecontent += '</ul>';
      }
      sidebar
        .addPanel({
          id:   key.toLowerCase(),
          tab:  '<i class="fa ' + value['icon'] + '"></i>',
          title: key,
          pane: '<div id="' + key.toLowerCase() + '-content">' + panecontent + '</div>'
        });
    });

    Object.entries(sidebarbottom).forEach(([key, value]) => {
      sidebar
        .addPanel({
          id:   key.toLowerCase(),
          tab:  '<i class="fa ' + value['icon'] + '"></i>',
          button: value['link'],
          position: 'bottom'
        });
    });
    
    sidebar
      .addPanel({
        id:   'info',
        tab:  '<i class="fa fa-info-circle"></i>',
        title: 'Information',
        pane: '<div id="infocontent"><h3>Information</h3></div>'
      });

    function openSidebar(item) {
      sidebar.open(item);
    }
    window.openSidebar = openSidebar;

    // Add Nominatim Search control
    if (getSetting('_mapSearch', 'off') !== 'off') {
      var geocoder = L.Control.geocoder({
        expand: 'click',
        position: getSetting('_mapSearch', 'topright'),
        
        geocoder: L.Control.Geocoder.nominatim({
          geocodingQueryParams: {
            viewbox: '',  // by default, viewbox is empty
            bounded: 1,
          }
        }),
      }).addTo(map);

      function updateGeocoderBounds() {
        var bounds = map.getBounds();
        geocoder.options.geocoder.options.geocodingQueryParams.viewbox = [
            bounds._southWest.lng, bounds._southWest.lat,
            bounds._northEast.lng, bounds._northEast.lat
          ].join(',');
      }

      // Update search viewbox coordinates every time the map moves
      map.on('moveend', updateGeocoderBounds);
    }

    // Add location control
    if (getSetting('_mapMyLocation', 'off') !== 'off') {
      var locationControl = L.control.locate({
        keepCurrentZoomLevel: true,
        returnToPrevBounds: true,
        position: getSetting('_mapMyLocation', 'topright')
      }).addTo(map);
    }
 
    // Add Routing Control
    if (getSetting('_mapRouting')) {
      var control = L.Routing.control({
        waypoints: [
          {latLng: undefined, latLng: undefined}
        ],
        routeWhileDragging: true,
        reverseWaypoints: true,
        showAlternatives: false,
        router: new L.Routing.osrmv1({
          language: 'de',
          profile: getSetting('_mapRouting')
        }),
        geocoder: L.Control.Geocoder.nominatim({}),
        collapsible: true,
        show: false,
        //collapsed: false,
        //expand: 'click',
        position: 'bottomright'
      }).addTo(map);

      window.startRouting = function(lat,lon) {
        control.spliceWaypoints(control.getWaypoints().length - 1, 1, [lat, lon]);
        locationControl.start();
        map.on('locationfound', function (e) {
          control.spliceWaypoints(0, 1, e.latlng);
          locationControl.stopFollowing();
        });
      }
    }

    showMap();

    function showMap() {
      if (completePoints) {
        let mapid = document.getElementById("map");
        mapid.style.visibility = "visible" ;
        let loader = document.getElementById("loader");
        loader.style.display = 'none';
        //$('#map').css('visibility', 'visible');
        //$('.loader').hide();

      } else {
        setTimeout(showMap, 50);
      }
    }
  }

  var csv = 'csv/Points.csv';
  fetchDataCsv(csv).then((data) => {
    var data = Papa.parse(data, {
      header: true,
      skipEmptyLines: true
    }).data;
    onMapDataLoad(data);
  });
};

async function fetchDataJson(url) {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function fetchDataCsv(url) {
  try {
    const response = await fetch(url);
    const data = await response.text();
    return data;
  } catch (err) {
    console.error(err);
  }
}


