const sidebartop = {
  'Home':
  {
    'icon': 'fa-bars',
    'description': '<h2>Impffrei-Suche</h2><p>Eine Karte für alle, die Wert auf C-impffrei legen.</p><p>Die Punkte auf der Karte können als <a href="table.html" target="_blank">Tabelle</a> angezeigt werden.</p>',
    'sites':
    [
      {
        'name': 'Impffrei-Suche',
        'url': 'https://www.impffrei-suche.ch'
      },
    ],
  },
};

module.exports = sidebartop;
//export {sidebartop};

