const sidebartop = {
  'Home':
  {
    'icon': 'fa-bars',
    'description': '<h2>Kraftorte Schweiz</h2><p>Karte und Informationen zu Orten der Kraft in der Schweiz</p><p>Die Kraftorte können als <a href="table.html" target="_blank">Tabelle</a> angezeigt werden.</p>',
    'sites':
    [
      {
        'name': 'Forschungsstelle Kraftorte Schweiz',
        'url': 'https://www.kraftorte.ch/'
      },
      {
        'name': 'Geomantigruppen Schweiz',
        'url': 'https://www.geomantiegruppen.ch/'
      },
      {
        'name': 'Kraftort Natur',
        'url': 'https://kraftort-natur.ch/'
      },
      {
        'name': 'Kraftorte der Schweiz',
        'url': 'https://www.kraftpl%C3%A4tze.ch/'
      },
      {
        'name': 'Kraftorte Schlieren und Umgebung',
        'url': 'https://www.kraftort-schlieren.ch/'
      },
      {
        'name': 'Magic Places - Schweizer Kraftorte',
        'url': 'https://magic-places.ch/'
      },
      {
        'name': 'Verband für Radiästhesie und Geobiologie Schweiz',
        'url': 'https://www.vrgs.ch'
      },
    ],
  },
  'Boviseinheiten':
  {
    'description': '<h2>Boviseinheiten</h2><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Bovis%2C_A._%28Vie_%C3%A0_la_campagne%2C_1935-08-01%29.jpg/235px-Bovis%2C_A._%28Vie_%C3%A0_la_campagne%2C_1935-08-01%29.jpg"/><p>Der französische Physiker Alfred Bovis hat für die Messung der Energie-Qualität eines Ortes das Biometer mit Angaben der Bovismesseinheiten entwickelt. Im Laufe der Jahre hat diese Methode auch in wissenschaftlichen Kreisen zunehmend Anerkennung gefunden. Die Bovis-Einheit ist keine physikalische Einheit, stattdessen ist diese rein intuitiv und abhängig vom Wünschelrutengänger.</p><p>Die Grundskala geht von 0 bis 10`000 Einheiten. 6`500 Einheiten sind ein neutraler Wert. Unter dem neutralen Wert liegende Einheiten bezeichnen Orte oder Gegebenheiten, die uns Energie entziehen. Darüber liegende Werte führen uns Energie zu. Werte über 10`000 wirken auf den subtilen, ätherischen Bereich. Noch höhere Werte umfassen das höhere Bewusstsein.</p>',
    'icon': 'fa-ruler-horizontal',
  },
  'Buecher':
  {
    'description': '<h2>Bücher</h2>',
    'icon': 'fa-book',
    'books':
    [
      {
        'name': 'Onlineshop - Andrea Fischbacher',
        'url': 'https://www.andrea-fischbacher.ch/shop/buecher'
      },
      {
        'name': '111 mystische Orte in der Schweiz, die man gesehen haben muss',
        'url': 'https://www.buchhaus.ch/de/detail/ISBN-9783740801397/Mansour-Monika/111-mystische-Orte-in-der-Schweiz-die-man-gesehen-haben-muss?bpmctrl=bpmrownr.1%7Cforeign.523452-1-0-0'
      },
      {
        'name': 'Magischer Bodensee',
        'url': 'https://at-verlag.ch/buch/978-3-03800-560-5/barbara-hutzl-ronge-magischer-bodensee.html'
      },
      {
        'name': 'Magisches Berner Oberland',
        'url': 'https://at-verlag.ch/buch/978-3-85502-729-3/pier-haenni-magisches-berner-oberland.html'
      },
      {
        'name': 'Magisches Zürich',
        'url': 'https://www.buchzentrum.ch/de/detail/ISBN-9783038002055/Hutzl-Ronge-Barbara/Magisches-Z%C3%BCrich'
      },
      {
        'name': 'Orte der Kraft',
        'url': 'https://www.buchzentrum.ch/de/detail/ISBN-9783855026319/Merz-Blanche/Orte-der-Kraft-in-der-Schweiz'
      },
      {
        'name': 'Orte der Kraft in der Schweiz',
        'url': 'https://www.buchhaus.ch/de/detail/ISBN-9783038005490/Brunschwiler-Jean-Pierre/Orte-der-Kraft-in-der-Schweiz?bpmctrl=bpmrownr.1%7Cforeign.523452-1-0-0'
      },
      {
        'name': 'Orte der Kraft im Tessin',
        'url': 'https://at-verlag.ch/buch/978-3-03800-773-9/claudio-andretta-orte-der-kraft-im-tessin.html'
      },
      {
        'name': 'Quellen der Kraft - Magische Orte in der Schweiz',
        'url': 'https://www.buchhaus.ch/de/detail/ISBN-9783259037294/Maurer-Raymond/Quellen-der-Kraft---Magische-Orte-in-der-Schweiz'
      },
    ],
  }
};
module.exports = sidebartop;
