const sidebartop = {
  'Home':
  {
    'icon': 'fa-bars',
    'description': '<h2>WIR - 🕯AbendSpaziergänge🕯 - Schweiz<br/><img src="https://gemeinsam-schweiz.ch/____impro/1/onewebmedia/LogofreiWebseiteNEU.png?etag=W%2F%22fc5f-621b9228%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=200%2B200" alt="Gemeinsam Schweiz"/></h2><p>Die Spaziergänge wurden aus verschiedenen Quellen gesammelt. Keine Garantie für Vollständigkeit und Richtigkeit der Angaben.</p><p>Inhaltliche Korrekturen/Ergänzungen bitte melden an: <a href="https://t.me/angst_frei_ch">Jörg</a>.<br/>Neue Ortsgruppen bitte mit Betreff melden an: <a href="https://t.me/jolafatush">@jolafatush</a>.<br/>Neue Kundgebungen und Vorträge  bitte melden an: <a href="mailto:kontakt@gemeinsam-schweiz.ch">kontakt@gemeinsam-schweiz.ch</a>.</p><h3>Icon Attribution</h3><p><a href="https://www.flaticon.com/packs/hope-7" title="icons">Icons created by Freepik - Flaticon</a></p>',
    'sites':
    [
      {
        'name': 'Tabellen-Ansicht',
        'url': 'table.html'
      },
      {
        'name': 'Telegram-Gruppe',
        'url': 'https://t.me/wir_abendspaziergaenge_schweiz'
      },
      {
        'name': 'Gemeinsam Schweiz',
        'url': 'https://gemeinsam-schweiz.ch/'
      },
    ],
  },
};

module.exports = sidebartop;

