const constants = {
  // Map Info
  '_mapTitle': 'Antarctica and beyond',
  // Map Settings
  '_markercluster': 'off',
  '_initZoom': '0',
  '_initLat': '-90',
  '_initLon': '0',
  // Map Controls
  '_mapSearch': false,
  '_mapRouting': false,
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': false,
  '_menuOpen': [],
  '_categorySelected': [],
  '_groupSelected': [],
  '_popupDisabled': [],
  '_heatmap': [],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Netzwerk', 'Grundversorgung', 'Notfall'],
  '_tableColumns': ['Kategorie', 'Gruppe', 'Image', 'Datum', 'Name', 'Beschreibung', 'Adresse', 'Kontakt', 'Online'],
};

module.exports = constants;

