const tilelayers = [
  {
    'category': 'Informationen',
    'name': 'Kantone',
    'url': 'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissboundaries3d-kanton-flaeche.fill/default/current/3857/{z}/{x}/{y}.png',
    'maxZoom': 21,
    'zIndex': 999
  },
  {
    'category': 'Informationen',
    'name': 'Gemeinden',
    'url': 'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill/default/current/3857/{z}/{x}/{y}.png',
    'maxZoom': 21,
    'zIndex': 998
  },
  {
    'category': 'Informationen',
    'name': 'Wanderwege',
    'url': 'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swisstlm3d-wanderwege/default/current/3857/{z}/{x}/{y}.png',
    'maxZoom': 21,
    'zIndex': 1000
  },
]
module.exports = tilelayers;
