const geojson = [
  {
    "category": "Schulen",
    "name": "Schulkreise",
    "url": "geodata/schulkreise.json",
    "color": "blue",
    "width": 2
  },
  {
    "category": "Netzwerk",
    "name": "Spielplatz",
    "icon": "fa-shapes",
    "url": "geodata/spielplaetze.json",
    "color": "orange",
    "opacity": 0.7,
    "width": 1.0
  },
]
module.exports = geojson;
