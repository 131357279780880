const geojson = [
  {
    "category": "Infrastruktur",
    "name": "Geldautomat",
    "icon": "fa-credit-card",
    "url": "geodata/geldautomat.json",
    "color": "green",
    "width": 1
  },
  {
    "category": "Infrastruktur",
    "name": "Öffentliche Toilette",
    "icon": "fa-toilet",
    "url": "geodata/toilette.json",
    "color": "brown",
    "width": 1
  },
  {
    "category": "Natur",
    "name": "Gewässer",
    "url": "geodata/gewaesser.json",
    "color": "blue",
    "width": 2
  },
  {
    "category": "Natur",
    "name": "Tobelwälder",
    "url": "geodata/wald.json",
    "color": "green",
    "width": 1
  },
  {
    "category": "Natur",
    "name": "Schutzwald",
    "url": "geodata/schutzwald.json",
    "color": "red",
    "width": 1
  },
  {
    "category": "Infrastruktur",
    "name": "Stromanlagen",
    "icon": "fa-bolt",
    "url": "geodata/stromanlagen.json",
    "color": "red",
    "width": 1
  },
  {
    "category": "Notfall",
    "name": "Defibrillator",
    "icon": "fa-heart-circle-bolt",
    "url": "geodata/defibrillator.json",
    "color": "orange",
    "width": 1
  },
  {
    "category": "Infrastruktur",
    "name": "Stromleitungen",
    "url": "geodata/stromleitungen.json",
    "color": "red",
    "width": 2
  },
]
module.exports = geojson;
