const constants = {
  // Map Info
  '_mapTitle': 'Notfallkarte',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '12',
  '_initLat': '47.37195765',
  '_initLon': '8.53789297',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapRouting': 'car',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Netzwerk', 'Grundversorgung'],
  '_categorySelected': ['Grundversorgung'],
  '_groupSelected': [],
  '_popupDisabled': ['Hydrant'],
  '_heatmap': ['Brunnen (Quellwasser)', 'Hydrant'],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Netzwerk', 'Grundversorgung', 'Notfall'],
  '_tableColumns': ['Kategorie', 'Gruppe', 'Image', 'Datum', 'Name', 'Beschreibung', 'Adresse', 'Kontakt', 'Online'],
};

module.exports = constants;

