const sidebarbottom = {
  'mail':
  {
    'icon': 'fa-envelope',
    'link': 'mailto:info@angst-frei.ch'
  },
  'www': 
  {
    'icon': 'fa-globe',
    'link': 'https://www.angst-frei.ch'
  },
  'donate': 
  {
    'icon': 'fa-donate',
    'link': 'https://www.paypal.com/donate?business=3UVB97V782WJL&no_recurring=0&item_name=Unterhalt+der+Karte.&currency_code=CHF'
  },
};

module.exports = sidebarbottom;

