const constants = {
  // Map Info
  '_mapTitle': 'Spaziergänge in der Schweiz',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '',
  '_initLat': '',
  '_initLon': '',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Spaziergang'],
  '_categorySelected': [],
  '_groupSelected': ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag', 'September 2022', 'Oktober 2022'],
};

module.exports = constants;

